
import PathFinder from 'geojson-path-finder';
import geojson from './geojson/kergliiklus.json';
import { validateBBox } from '@turf/helpers';
import nearestPoint from '@turf/nearest-point';
import * as turf from '@turf/turf'
const { NominatimJS } = require('nominatim-js');

console.log(turf);
//const L = require('leaflet');
//const leafletMap = require('leaflet-map');

const point = require('turf-point');

var pathFinder = new PathFinder(geojson, {
    weightFn: function (a, b, props) {
        return props.tee;

        // var dx = a[0] - b[0];
        // var dy = a[1] - b[1];
        // return Math.sqrt(dx * dx + dy * dy);
    }
});

//console.log(pathFinder);

$(document).ready(function () {

    var resultPath = null;
    var geojson_arr = [];

    var allPoints = [];
    var allFeatures = geojson.features;

    var routePath = {
        start: null,
        destination: null
    }

    var startMarkerIcon = L.icon({
        iconUrl: 'images/marker_start.svg'
    });
    var destinationMarkerIcon = L.icon({
        iconUrl: 'images/marker_finis.svg'
    });

    var routeMarkers = {
        start: L.marker([0,0], {icon: startMarkerIcon}),
        destination: L.marker([0,0], {icon: destinationMarkerIcon})
    }

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position);
        });
    } else {
        console.log("Geolocation is not supported by this browser.");
    }

    for (var i = 0; i < allFeatures.length; i++) {
        for (var j = 0; j < allFeatures[i].geometry.coordinates.length; j++) {
            if (allFeatures[i].geometry && allFeatures[i].geometry.coordinates) {
                allPoints.push(point(allFeatures[i].geometry.coordinates[j]));
            }

        }
    }
    allPoints = turf.featureCollection(allPoints);

    $('#start').on('input', function (e) {
        findLocation($(this).val(), "start");
    });
    $('#destination').on('input', function (e) {
        findLocation($(this).val(), "destination");
    });

    /**
     * 
     * @param {String} query 
     * @param {String | "start" || "destination"} which 
     */
    function findLocation(query, which) {
        
        if (geojson_arr.length > 0) {
            // ---
            for (var i = 0; i < geojson_arr.length; i++) {
                geojson_arr[i].removeFrom(map);
            }
            // ---
        }

        if (resultPath) {
            resultPath.removeFrom(map);
        }

        NominatimJS.search({
            q: query
        }).then(results => {
            console.log(results);
            
            $(".search_list").empty().append("<ul>");

            for (var i = 0; i < results.length; i++) {
                $(".search_list > ul").append("<li from='" + which + "' display='" + results[i].display_name + "' lon='" + results[i].lon + "' lat='" + results[i].lat + "'><h4>" + results[i].display_name + "</h4></li>");
            }

            $(".search_list > ul li").on('click', function (e) {
                var coords = {
                    lon: parseFloat($(this).attr('lon')),
                    lat: parseFloat($(this).attr('lat'))
                }
                routePath[which] = coords;
                addMarker(which, coords);
                $("#"+which).val($(this).attr("display"));
                selectRoute(routePath.start, routePath.destination);
                $(".search_list").hide();
            });

            $(".search_list").show();


        }).catch(error => {
            // error ocurred
            console.log(error);
            console.log("error");
        })
    }

    function selectRoute(start, destination) {
        if(start == null || destination == null) {
            return;
        }
        var _nearestStart = nearestPoint(point([start.lon, start.lat]), allPoints);
        var _nearestDestination = nearestPoint(point([destination.lon, destination.lat]), allPoints);

        var path = pathFinder.findPath(_nearestStart, _nearestDestination);

        var myLines = [{
            "type": "LineString",
            "coordinates": path.path
        }];

        resultPath = L.geoJSON(myLines, {
            style: {
                color: 'red',
                weight: 5,
                opacity: 1
            }
        }).addTo(map);
        map.fitBounds(resultPath.getBounds());

        var takistused_rajal = [];
        for (var i = 0; i < path.path.length; i++) {
            for (var j = 0; j < takistus.features.length; j++) {
                if (path.path[i][0] == takistus.features[j].geometry.coordinates[0] && path.path[i][1] == takistus.features[j].geometry.coordinates[1]) {
                    takistused_rajal.push(takistus.features[j]);
                }
            }
        }
        var takistused_rajal_geojson = {
            "type": "FeatureCollection",
            "name": "takistused",
            "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
            "features": takistused_rajal
        };
        console.log(takistused_rajal);
        addObjectsToList(takistused_rajal);

        var uued_takistused_rajal = split_takistus(takistused_rajal_geojson);
        console.log(uued_takistused_rajal);

        for (var key in uued_takistused_rajal) {
            if (uued_takistused_rajal.hasOwnProperty(key)) {
                geojson_arr.push(draw_takistus(uued_takistused_rajal[key], true));
            }
        }
    }

    function addMarker(which, coords) {
        console.log(which, coords);

        var newLatLng = new L.LatLng(coords.lat, coords.lon);
        
        routeMarkers[which].setLatLng(newLatLng);
        routeMarkers[which].addTo(map);
    }

    function addObjectsToList(geojson) {
        var features = geojson;
        $('.sidebar_list_content').empty();
        for(var i = 0; i < features.length; i++) {
            $('.sidebar_list_content').append(
                '<li>' +
                '<div class="sedebar_list_item">' +
                    '<h3>' + getTakistuseNimi(features[i].properties.takistus) + '</h3>' +
                    '<h4>' + features[i].properties.markus + '</h4>' +
                '</div>' +
                '<div class="sidebar_list_item_icon" style="background-image: url(images/marker_lvl_' + features[i].properties.takistus + '.svg)"></div>' +
                '</li>'
            );
        }
    }

});

